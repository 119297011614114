import { scrollTo } from '/util';

/* This scroll keeps the item always to the 1st in the visible list */

const OVERFLOW_THRESHOLD = 96;
var offset = 0;

var nextTestimonial = function(e) {
  var $this = $(this),
    $scroller = $this.siblings('.scroller'),
    itemWidth = $scroller.find('.item').outerWidth(),
    currentPos = $scroller.scrollLeft();

  var totalItems = $scroller.find('.item'),
    middleOfItems = [],
    closestTo = totalItems.length * itemWidth - itemWidth / 2 - 24, // Center of last child - margin between cards
    itemNo = totalItems.length - 1;

  for (var i = 0; i < totalItems.length; i++) {
    middleOfItems[i] = i * (totalItems[i].clientWidth + 24);

    var cl = middleOfItems[i] - currentPos;
    if (cl > itemWidth / 17 && closestTo > cl) {
      // Scroll length must be greater than itemWidth/17
      closestTo = cl;
      itemNo = i;
    }
  }

  scrollTo.call($scroller[0], middleOfItems[itemNo]);
};

var prevTestimonial = function(e) {
  var $this = $(this),
    $scroller = $this.siblings('.scroller'),
    itemWidth = $scroller.find('.item').outerWidth(),
    currentPos = $scroller.scrollLeft();

  var totalItems = $scroller.find('.item'),
    middleOfItems = [],
    closestTo = totalItems.length * itemWidth - itemWidth / 2 - 24, // Center of last child - margin between cards
    itemNo = totalItems.length - 1;

  for (var i = 0; i < totalItems.length; i++) {
    middleOfItems[i] = i * (totalItems[i].clientWidth + 24);

    var cl = currentPos - middleOfItems[i];

    if (cl > itemWidth / 17 && closestTo > cl) {
      // Scroll length must be greater than itemWidth/17
      closestTo = cl;
      itemNo = i;
    }
  }

  scrollTo.call($scroller[0], middleOfItems[itemNo]);
};

$('.js-carousel-left .next-arrow').click(nextTestimonial);
$('.js-carousel-left .prev-arrow').click(prevTestimonial);

$('.js-carousel-left .scroller').each(function() {
  this.addEventListener(
    'scroll',
    function() {
      var $this = $(this),
        currentPos = $this.scrollLeft(),
        scrollWidth = this.scrollWidth,
        itemWidth = $this.find('.item').outerWidth();

      if (currentPos < OVERFLOW_THRESHOLD) {
        $this.siblings('.prev-arrow').addClass('hide');
      } else if (
        scrollWidth - currentPos - $this.find('.wrapper').innerWidth() <
        OVERFLOW_THRESHOLD
      ) {
        $this.siblings('.next-arrow').addClass('hide');
      } else {
        $this.siblings('.prev-arrow, .next-arrow').removeClass('hide');
      }
    },
    { passive: true }
  );
});
