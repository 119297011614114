import { debounce } from 'modules/util';
import './modules/lefthand-carousel.js';

window._eventCategory = 'Tech Hiring Page';
var isMobileDevice = false;
var oldtValues;
var currentSection = 0;

var easing = function(t) {
  return t * (2 - t);
};

var easing2 = function(t) {
  return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
};

var sections = $('#sections .section');

var sectionBounds = new Array(sections.length);

function setSectionBounds() {
  sections.each(function(i, section) {
    var offset = $(section).offset();
    sectionBounds[i] = [offset.top >> 0, $(section).outerHeight() >> 0];
  });
}

setSectionBounds();

$(document).ready(setSectionBounds);

function setImagePos(n) {
  var translateX = -(720 * n);
  var translateY = -(370 * n);

  $('.images-carousal').css({
    webkitTransform: 'translate(' + translateX + 'px, ' + translateY + 'px)',
    mozTransform: 'translate(' + translateX + 'px, ' + translateY + 'px)',
    msTransform: 'translate(' + translateX + 'px, ' + translateY + 'px)',
    oTransform: 'translate(' + translateX + 'px, ' + translateY + 'px)',
    transform: 'translate(' + translateX + 'px, ' + translateY + 'px)'
  });
}

function setOpacity(tValues) {
  $('.images .image').each(function(i, image) {
    var t = tValues[i];

    $(image).css('opacity', t);
  });

  $('.captions .caption').each(function(i, caption) {
    var opacity = tValues[i];
    var pointerEvents = 'none';

    if (opacity) {
      pointerEvents = 'all';
    }

    $(caption).css({
      opacity: opacity,
      pointerEvents: pointerEvents
    });
  });
}

function gotoSection(idx, fromSection) {
  if (idx === 2) {
    $('body, html')
      .stop()
      .animate({ scrollTop: sectionBounds[2][0] }, 1000, 'swing');

    // If fromSection is present means it has directly landed on this page through some CTA (Currently for View Openings)
    if (typeof fromSection !== 'undefined') {
      window.rzpAnalytics({
        eventAction: 'View Openings',
        eventLabel: 'section_' + fromSection
      });
    }
  }

  currentSection = idx;
  var tValues = new Array(sectionBounds.length).fill(0);
  tValues[idx] = 1;
  setImagePos(idx);
  setOpacity(tValues);

  if (idx === 1) {
    var order = [4, 5, 2, 1, 3, 0];
    $('#canvas .image-rotator .item')
      .addClass('pre-bouncein')
      .removeClass('bouncein')
      .each(function(i, item) {
        setTimeout(function() {
          $(item)
            .removeClass('pre-bouncein')
            .addClass('bouncein');
        }, 1000 + 100 * order[i]);
      });
  } else if (idx === 5) {
    var showcaseImg = $('#canvas .showcase-image.section6');
    showcaseImg.addClass('pre-bouncein').removeClass('bouncein');
    setTimeout(function() {
      showcaseImg.removeClass('pre-bouncein').addClass('bouncein');
    }, 500);
  }

  $('.progress .progress-inner').css({
    width: (idx / (sectionBounds.length - 1)) * 100 + '%'
  });
}

window.gotoSection = gotoSection;

/**
 * Image Rotator
 */

$('.image-rotator .item').each(function(i, item) {
  var interval = 2000 + Math.random() * 8000;
  interval >>= 0;

  var currImage = 0;
  setInterval(function() {
    var imgs = $(item)
      .find('img')
      .css('opacity', 0);
    imgs.eq(currImage).css('opacity', 1);

    currImage += 1;
    currImage %= 3;
  }, interval);
});

/**
 * Typewriter effect
 */

// $('h1.typewriter, h2.typewriter').each(function(i, typewriter) {
//   var $typewriter = $(typewriter);
//   var $texts = $typewriter.siblings('.typewriter-content');
//   var length = $texts.length;
//   var currText = 0;

//   var switchTitles = function() {
//     var text = $texts.eq(currText).html();
//     var textLength = text.length;
//     var carat = currText === 0 ? 1 : 3;
//     $typewriter.removeClass('blink');

//     $('.images #image0, #section0 .image')
//       .removeClass('section0-1 section0-2 section0-3 anim')
//       .addClass('section0-' + (currText + 1) + ' anim');

//     var interval = setInterval(function() {
//       if (carat <= textLength) {
//         $typewriter.html(text.substr(0, carat));
//         carat++;
//       } else {
//         clearInterval(interval);
//         currText++;
//         currText %= length;
//         $typewriter.addClass('blink');

//         setTimeout(function() {
//           $typewriter.removeClass('blink');
//           var interval2 = setInterval(function() {
//             carat--;
//             if (carat > 2) {
//               var t = text.substring(0, carat);
//               $typewriter.html(t);
//             } else {
//               $typewriter.addClass('blink');
//               clearInterval(interval2);
//               setTimeout(switchTitles, 1000);
//             }
//           }, 50);
//         }, 5000);
//       }
//     }, 100);
//   };

//   switchTitles();
// });

// $('h1.fade-titles, h2.fade-titles').each(function(i, titles) {
//   var curr = 0;
//   var $titles = $(titles).find('.fade-content');
//   var showItem = function() {
//     var title = $titles.eq(curr);
//     $titles.css({
//       opacity: 0
//     });
//     title.css({
//       opacity: 1
//     });
//     curr++;
//     curr %= $titles.length;
//   };
//   setInterval(showItem, 5000);

//   showItem();
// });

//for auto increase of counters
setInterval(function() {
  increaseCounter(document.getElementById('burger'));
}, 1500);
setInterval(function() {
  increaseCounter(document.getElementById('hotel'));
}, 2000);
setInterval(function() {
  increaseCounter(document.getElementById('train'));
}, 1000);

function increaseCounter(div) {
  var count = parseInt(div.innerText);
  div.innerText = count + Math.floor(Math.random() * 5) + 1;
}

function updateDeviceFlag(x) {
  if (x.matches) {
    isMobileDevice = true;
  } else {
    isMobileDevice = false;
  }
}

var x = window.matchMedia('(max-width: 1023px)'); // Should be same as when overflow:hidden is there on body
x.addListener(updateDeviceFlag);
updateDeviceFlag(x);
